<template>
  <b-modal
    id="verify-modal"
    :visible="shallShowModal"
    title="资源审核"
    centered
    no-close-on-backdrop
    static
    cancel-title="取消"
    cancel-variant="outline-secondary"
    ok-title="确定"
    @change="(val) => $emit('update:shall-show-modal', val)"
    @ok="updateForm"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title"> 资源审核 </h5>
      <div class="modal-actions">
        <feather-icon icon="XIcon" class="ml-1 cursor-pointer" @click="$emit('update:shall-show-modal', false)" />
      </div>
    </template>

    <validation-observer ref="refFormObserver">
      <b-form>
        <b-row>
          <!-- status -->
          <b-col cols="12">
              <b-form-group label="审核意见" label-cols-md="2" label-for="status">
                <b-form-radio-group id="status" class="col-form-label" v-model="formData.status" :options="statusOptions" />
              </b-form-group>
          </b-col>

          <!-- remark -->
          <b-col cols="12">
            <b-form-group label="审核备注" label-for="remark" label-cols-md="2">
                <b-form-textarea id="remark" v-model="formData.remark" placeholder="审核备注" rows="2" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

  </b-modal>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationObserver } from 'vee-validate'

export default {
  components: {
    formValidation,
    ValidationObserver,
  },
  model: {
    prop: 'shallShowModal',
    event: 'update:shall-show-modal',
  },
  props: {
    shallShowModal: {
      type: Boolean,
      required: true,
    },
    extra: {
      type: Object,
      required: false,
    },
  },
  setup(props, { emit }) {
    /* 必要组件加载区域 */

    // 权限组件 弹出确认组件

    /* 组件页面数据准备区域，包括表单，下拉选择项，数据整理操作等 */

    // 表单验证dom对象
    const refFormObserver = ref(null)
    // 表单数据项
    const formData = ref({
      status: 1,
      remark: '',
    })
    // 表单数据清空函数
    const resetFormData = () => {
      formData.value = {
        status: 1,
        remark: '',
      }
      refFormObserver.value.reset()
    }

    // 状态
    const statusOptions = ref([
      { text: '通过', value: 1 },
      { text: '否决', value: -1 },
    ])

    /* 接口调用区域 */

    // 保存 处理编辑
    const updateForm = () => {
      refFormObserver.value.validate().then(success => {
        if (success) {
          emit('up-verify', formData.value, props.extra)
          emit('update:shall-show-modal', false)
        }
      })
    }

    /* 组件内部逻辑处理，生命周期问题处理区域 */

    // 编辑窗口关闭，清空表单并刷新验证
    watch(() => props.shallShowModal, val => {
      if (!val) { setTimeout(() => { resetFormData() }, 300) }
    })

    return {
      formData,
      refFormObserver,
      statusOptions,
      updateForm,
    }
  },
}
</script>
