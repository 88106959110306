import request from '@/auth/jwt/request'

/**
 * @description 员工海报分类 -- 员工海报列表
 * @param {Object} param params {Object} 传值参数
 */
export const staffImageListApi = params => { return request('staff-poster/list', 'get', params) }

/**
 * @description 员工海报分类 -- 员工海报状态管理
 * @param {Object} param params {Object} 传值参数
 */
export const staffImageStatusApi = params => { return request('staff-poster/status', 'get', params) }
 
/**
 * @description 员工海报分类 -- 员工海报审核
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const staffImageVerifyApi = data => { return request('staff-poster/verify', 'put', {}, data) }

/**
 * @description 员工视频分类 -- 员工视频列表
 * @param {Object} param params {Object} 传值参数
 */
export const staffVideoListApi = params => { return request('staff-video/list', 'get', params) }

/**
* @description 员工视频分类 -- 员工视频状态管理
* @param {Object} param params {Object} 传值参数
*/
export const staffVideoStatusApi = params => { return request('staff-video/status', 'get', params) }
  
 
/**
* @description 员工视频分类 -- 员工视频审核
* id = 0 新增
* @param {Object} param params {Object} 传值参数
*/
export const staffVideoVerifyApi = data => { return request('staff-video/verify', 'put', {}, data) }

/**
* @description 员工海报分类 -- 下拉
* id = 0 新增
* @param {Object} param params {Object} 传值参数
*/
export const staffPosterCategoryApi = () => { return request('pull-down/staff-poster-category', 'get') }

/**
* @description 员工视频分类 -- 下拉
* id = 0 新增
* @param {Object} param params {Object} 传值参数
*/
export const staffVideoCategoryApi = () => { return request('pull-down/staff-video-category', 'get') }